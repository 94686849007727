import React, { useContext, useState, useEffect } from 'react'
import LangCtx from '../../LangCtx'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Badge } from 'react-bootstrap'
import {
  parseQuery,
  getProductAmmattiRyhmaName,
  getProductStandardName,
  getMateriaaliOminaisuusName,
  getCategoryTranslationByFinnishString,
  getFilterTranslationByFinnishString } from '../../utils/getFunctions'
import chevron from '../../images/chevron.svg'
import './Filter.scss'

// Localization
import { FormattedMessage } from 'react-intl'

const Filter = (props) => {
  const {
    itemList,
    setItemList,
    defaultList,
    category,
    keyword,
    source,
    currentPage,
    setCurrentPage,
    setSort,
    setShowFilter,
    title,
    getSize,
    utils
  } = props

  const navigate = useNavigate()
  const { urlLang } = useParams()
  const lang = useContext(LangCtx)

  const initialFilter = {
    genre: [],
    istutus: [],
    brandi: [],
    malli: [],
    materiaali: [],
    painoLuokka: [],
    kaulaAukko: [],
    koko: [],
    kayttovinkki: [],
    vari: [],
    tuoteRyhma: [],
    alaRyhma: [],
    ajankohtaista: [],
    ammattiryhma: [],
    standardi: [],
    materiaaliOminaisuus: []
  }

  const [filter, updateFilter] = useState(initialFilter)
  const [hintaValue, setHintaValue] = useState(undefined)

  // FILTERIT
  // Ensimmäinen määrittää onko filter käytössä
  // Toinen määrittää kuinka monta checkboxia filteristä on päällä
  // Kolmas määrittää onko filterin valikko auki vai kiinni
  // Neljäs on tagilätkän state
  const [genreOn, setGenreOn] = useState(false)
  const [filterAmountGenre, setFilterAmountGenre] = useState(0)
  const [hideGenre, toggleHideGenre] = useState(true)
  const [tagGenre, setTagGenre] = useState(null)

  const [istutusOn, setIstutusOn] = useState(true)
  const [filterAmountIstutus, setFilterAmountIstutus] = useState(0)
  const [hideIstutus, toggleHideIstutus] = useState(true)
  const [tagIstutus, setTagIstutus] = useState(null)

  const [brandiOn, setBrandiOn] = useState(true)
  const [filterAmountBrandi, setFilterAmountBrandi] = useState(0)
  const [hideBrandi, toggleHideBrandi] = useState(true)
  const [tagBrandi, setTagBrandi] = useState(null)

  const [malliOn, setMalliOn] = useState(false)
  const [filterAmountMalli, setFilterAmountMalli] = useState(0)
  const [hideMalli, toggleHideMalli] = useState(true)
  const [tagMalli, setTagMalli] = useState(null)

  const [materiaaliOn, setMateriaaliOn] = useState(true)
  const [filterAmountMateriaali, setFilterAmountMateriaali] = useState(0)
  const [hideMateriaali, toggleHideMateriaali] = useState(true)
  const [tagMateriaali, setTagMateriaali] = useState(null)

  const [materiaaliOminaisuusOn, setMateriaaliOminaisuusOn] = useState(true)
  const [filterAmountMateriaaliOminaisuus, setFilterAmountMateriaaliOminaisuus] = useState(0)
  const [hideMateriaaliOminaisuus, toggleHideMateriaaliOminaisuus] = useState(true)
  const [tagMateriaaliOminaisuus, setTagMateriaaliOminaisuus] = useState(null)

  const [painoLuokkaOn] = useState(true)
  const [filterAmountPainoLuokka, setFilterAmountPainoLuokka] = useState(0)
  const [hidePainoLuokka, toggleHidePainoLuokka] = useState(true)
  const [tagPainoLuokka, setTagPainoLuokka] = useState(null)

  const [kaulaAukkoOn, setKaulaAukkoOn] = useState(true)
  const [filterAmountKaulaAukko, setFilterAmountKaulaAukko] = useState(0)
  const [hideKaulaAukko, toggleHideKaulaAukko] = useState(true)
  const [tagKaulaAukko, setTagKaulaAukko] = useState(null)

  const [kokoOn, setKokoOn] = useState(false)
  const [filterAmountKoko, setFilterAmountKoko] = useState(0)
  const [hideKoko, toggleHideKoko] = useState(true)
  const [tagKoko, setTagKoko] = useState(null)

  const [kayttovinkkiOn] = useState(true)
  const [filterAmountKayttovinkki, setFilterAmountKayttovinkki] = useState(0)
  const [hideKayttovinkki, toggleHideKayttovinkki] = useState(true)
  const [tagKayttovinkki, setTagKayttovinkki] = useState(null)

  const [variOn] = useState(true)
  const [filterAmountVari, setFilterAmountVari] = useState(0)
  const [hideVari, toggleHideVari] = useState(true)
  const [tagVari, setTagVari] = useState(null)

  const [tuoteRyhmaOn, setTuoteRyhmaOn] = useState(true)
  const [filterAmountTuoteRyhma, setFilterAmountTuoteRyhma] = useState(0)
  const [hideTuoteRyhma, toggleHideTuoteRyhma] = useState(true)
  const [tagTuoteRyhma, setTagTuoteRyhma] = useState(null)

  const [alaRyhmaOn, setAlaRyhmaOn] = useState(true)
  const [filterAmountAlaRyhma, setFilterAmountAlaRyhma] = useState(0)
  const [hideAlaRyhma, toggleHideAlaRyhma] = useState(true)
  const [tagAlaryhma, setTagAlaryhma] = useState(null)

  const [ajankohtaistaOn] = useState(true)
  const [filterAmountAjankohtaista, setFilterAmountAjankohtaista] = useState(0)
  const [hideAjankohtaista, toggleHideAjankohtaista] = useState(true)
  const [tagAjankohtaista, setTagAjankohtaista] = useState(null)

  const [ammattiryhmaOn, setAmmattiryhmaOn] = useState(false)
  const [filterAmountAmmattiryhma, setFilterAmountAmmattiryhma] = useState(0)
  const [hideAmmattiryhma, toggleHideAmmattiryhma] = useState(true)
  const [tagAmmattiryhma, setTagAmmattiryhma] = useState(null)

  const [standardiOn, setStandardiOn] = useState(false)
  const [filterAmountStandardi, setFilterAmountStandardi] = useState(0)
  const [hideStandardi, toggleHideStandardi] = useState(true)
  const [tagStandardi, setTagStandardi] = useState(null)

  const [lockAlaRyhma, setLockAlaRyhma] = useState(false)
  const [lockIstutus, setLockIstutus] = useState(false)
  const [lockKaulaAukko, setLockKaulaAukko] = useState(false)
  const [lockKoko, setLockKoko] = useState(false)
  const [lockMalli, setLockMalli] = useState(false)
  const [lockMateriaali, setLockMateriaali] = useState(false)
  const [lockMateriaaliOminaisuus, setLockMateriaaliOminaisuus] = useState(false)
  const [lockPainoLuokka, setLockPainoLuokka] = useState(false)

  const [jsxGenre, setJsxGenre] = useState(null)
  const [jsxIstutus, setJsxIstutus] = useState(null)
  const [jsxBrandi, setJsxBrandi] = useState(null)
  const [jsxPainoluokka, setJsxPainoluokka] = useState(null)
  const [jsxKaulaAukko, setJsxKaulaAukko] = useState(null)
  const [jsxKoko, setJsxKoko] = useState(null)
  const [jsxKayttovinkki, setJsxKayttovinkki] = useState(null)
  const [jsxVari, setJsxVari] = useState(null)
  const [jsxTuoteryhma, setJsxTuoteryhma] = useState(null)
  const [jsxAlaryhma, setJsxAlaryhma] = useState(null)
  const [jsxMateriaali, setJsxMateriaali] = useState(null)
  const [jsxMateriaaliOminaisuus, setJsxMateriaaliOminaisuus] = useState(null)
  const [jsxMalli, setJsxMalli] = useState(null)
  const [jsxAjankohtaista, setJsxAjankohtaista] = useState(null)
  const [jsxAmmattiryhma, setJsxAmmattiryhma] = useState(null)
  const [jsxStandardi, setJsxStandardi] = useState(null)

  // Kaikkien filttereiden sulkeminen (checkboxit piiloon)
  const toggleHideAll = () => {
    toggleHideTuoteRyhma(true)
    toggleHideAlaRyhma(true)
    toggleHideMalli(true)
    toggleHideMateriaali(true)
    toggleHideMateriaaliOminaisuus(true)
    toggleHidePainoLuokka(true)
    toggleHideAjankohtaista(true)
    toggleHideKayttovinkki(true)
    toggleHideBrandi(true)
    toggleHideVari(true)
    toggleHideGenre(true)
    toggleHideIstutus(true)
    toggleHideKaulaAukko(true)
    toggleHideKoko(true)
    toggleHideAmmattiryhma(true)
    toggleHideStandardi(true)
  }

  const hideAndLockSubFields = (hide, lock) => {
    toggleHideIstutus(hide)
    toggleHideKaulaAukko(hide)
    toggleHideKoko(hide)
    toggleHideMalli(hide)
    toggleHideMateriaali(hide)
    toggleHideMateriaaliOminaisuus(hide)
    toggleHidePainoLuokka(hide)
    setLockIstutus(lock)
    setLockKaulaAukko(lock)
    setLockKoko(lock)
    setLockMalli(lock)
    setLockMateriaali(lock)
    setLockMateriaaliOminaisuus(lock)
    setLockPainoLuokka(lock)
  }

  const resetFilter = () => {
    const q = parseQuery(window.location.search)
    toggleHideAll()

    switch (source) {
      case 'search':
        navigate(
          `/${urlLang}/${source}/${encodeURIComponent(keyword.replace(/%/g, '~~pct~~'))}?page=1&sort=${
            q.sort
          }&price=0,0&filter={}`
        )
        break
      case 'browse':
      case 'campaign':
        navigate(`/${urlLang}/${source}/${category}?page=1&sort=${q.sort}&price=0,0&filter={}`)
        break
      case 'outlet':
        navigate(`/${urlLang}/${source}?page=1&sort=${q.sort}&price=0,0&filter={}`)
        break
      default:
        navigate(`/${urlLang}`)
    }
  }

  // Init toiminnot kun tullaan ekan kerran sivulle
  useEffect(() => {
    if (!window.location.search) {
      switch (source) {
        case 'browse':
        case 'campaign':
          navigate(`/${urlLang}/${source}/${category}?page=1&sort=Nimi (A-Ö)&price=0,0&filter={}`, {
            replace: true,
          })
          break
        case 'search':
          navigate(
            `/${urlLang}/${source}/${encodeURIComponent(
              keyword.replace(/%/g, '~~pct~~')
            )}?page=1&sort=Hakupisteet&price=0,0&filter={}`,
            {
              replace: true,
            }
          )
          break
        case 'outlet':
          navigate(`/${urlLang}/${source}?page=1&sort=Nimi (A-Ö)&price=0,0&filter={}`, { replace: true })
          break
        default:
          navigate(`/${urlLang}`)
      }
    }

    if (window.location.search) {
      const search = window.location.search
      const q = parseQuery(search)

      q.page ? setCurrentPage(parseInt(q.page)) : setCurrentPage(1)
      q.sort ? setSort(q.sort) : setSort('Hakupisteet')
      q.filter !== '{}' ? updateFilterFromURL(q.filter) : updateFilter(initialFilter)
      q.price ? setHintaValue(JSON.parse('[' + q.price + ']')) : setHintaValue([0, 0])
      toggleHideAll()
    }
    // eslint-disable-next-line
  }, [category, keyword])

  // Kun URL:n filter osa muuttuu, tehdä päivitykset Filterin stateen
  // 4 parametria: page, sort, price, filter
  useEffect(() => {
    const q = parseQuery(window.location.search)
    const urlPage = q.page
    const urlPageInt = parseInt(urlPage)
    if (q.sort) setSort(q.sort)
    if (q.price) setHintaValue(JSON.parse('[' + q.price + ']'))
    if (q.filter) updateFilterFromURL(q.filter)
    if (urlPageInt && urlPageInt !== currentPage) setCurrentPage(urlPageInt)
    // eslint-disable-next-line
  }, [window.location.search])

  useEffect(() => {
    if (alaRyhmaOn) {
      hideAndLockSubFields(true, true)
    } else {
      hideAndLockSubFields(true, false)
    }

    if (tuoteRyhmaOn) {
      setLockAlaRyhma(true)
      toggleHideAlaRyhma(true)
    } else {
      setLockAlaRyhma(false)
    }
  }, [tuoteRyhmaOn, alaRyhmaOn])

  // Sama kuin alempana alaryhmällä, mutta myös alaryhmä lukitaan ja suljetaan
  useEffect(() => {
    if (filterAmountTuoteRyhma) {
      setLockAlaRyhma(false)
      toggleHideAlaRyhma(false)
    } else if (tuoteRyhmaOn && !filterAmountTuoteRyhma) {
      setLockAlaRyhma(true)
      toggleHideAlaRyhma(true)
      hideAndLockSubFields(true, true)
    }
    // eslint-disable-next-line
  }, [filterAmountTuoteRyhma])

  // Jos alaryhmä filtteri on päällä ja siinä ei ole valintoja,
  // laitetaan lukkoon ja nollataan seuraavat filtterit (6 kpl):
  // istutus, kaula-aukko, malli, materiaali, materiaaliOminaisuus, painoluokka
  useEffect(() => {
    if (alaRyhmaOn && filterAmountAlaRyhma) {
      hideAndLockSubFields(false, false)
    } else if (alaRyhmaOn && !filterAmountAlaRyhma) {
      hideAndLockSubFields(true, true)
    }
    // eslint-disable-next-line
  }, [filterAmountAlaRyhma])

  // Koko-filterin toggleri(?)
  // (Tarpeellinen jos halutaan koko-filter kategorioihin, joissa alaryhmä ja genre valitaan manuaalisesti?)
  // useEffect(() => {
  //   if (!alaRyhmaOn || !genreOn) {
  //     console.log('Koko piiloon ja disabled.')
  //   } else if (alaRyhmaOn && genreOn && (!filterAmountAlaRyhma || !filterAmountGenre)) {
  //     console.log('Koko esiin mutta disabled.')
  //   } else if (alaRyhmaOn && genreOn && filterAmountAlaRyhma && filterAmountGenre) {
  //     console.log('Koko esiin ja enabled.')
  //   }
  //   // eslint-disable-next-line
  // }, [alaRyhmaOn, genreOn, filterAmountAlaRyhma, filterAmountGenre])

  // Tällä valitaan, mitkä filterit ovat aktiivisia.
  // Sivu (kategoria, haku, kampanja, outlet) määrittää argumentit.
  // a) Tuoteryhmä, b) Istutus, c) Kaula-aukko,
  // d) Brändi, e) Malli, f) Materiaali JA MateriaaliOminaisuus g) Alaryhmä
  // i) Ammattiryhmä j) Standardi
  const selectFilters = (a, b, c, d, e, f, g, h, i = false, j = false) => {
    setTuoteRyhmaOn(a) // true tai false
    setIstutusOn(b) // true tai false
    setKaulaAukkoOn(c) // true tai false
    setBrandiOn(d) // true tai false
    setMalliOn(e) // true tai false
    setMateriaaliOn(f) // 'tuoteryhmä' tai 'kaikki'
    setMateriaaliOminaisuusOn(f) // 'tuoteryhmä' tai 'kaikki'
    setAlaRyhmaOn(g)
    setKokoOn(h) // true tai false
    setAmmattiryhmaOn(i) // true tai false
    setStandardiOn(j) // true tai false
  }

  // Päivittää filter staten URL:n perusteella
  const updateFilterFromURL = (string) => {
    const obj = JSON.parse(string)
    if (!obj.brandi) obj.brandi = []
    if (!obj.genre) obj.genre = []
    if (!obj.istutus) obj.istutus = []
    if (!obj.painoLuokka) obj.painoLuokka = []
    if (!obj.kaulaAukko) obj.kaulaAukko = []
    if (!obj.koko) obj.koko = []
    if (!obj.kayttovinkki) obj.kayttovinkki = []
    if (!obj.vari) obj.vari = []
    if (!obj.tuoteRyhma) obj.tuoteRyhma = []
    if (!obj.alaRyhma) obj.alaRyhma = []
    if (!obj.malli) obj.malli = []
    if (!obj.materiaali) obj.materiaali = []
    if (!obj.materiaaliOminaisuus) obj.materiaaliOminaisuus = []
    if (!obj.ajankohtaista) obj.ajankohtaista = []
    if (!obj.ammattiryhma) obj.ammattiryhma = []
    if (!obj.standardi) obj.standardi = []
    if (obj.brandi && obj.brandi.length) {
      if (obj.brandi.includes('BC')) {
        obj.brandi.push('B&C')
        obj.brandi = obj.brandi.filter((b) => b !== 'BC')
      }
      if (obj.brandi.includes('JackMaker')) {
        obj.brandi.push('Jack&Maker')
        obj.brandi = obj.brandi.filter((b) => b !== 'JackMaker')
      }
    }
    setFilterAmountGenre(obj.genre.length)
    setFilterAmountIstutus(obj.istutus.length)
    setFilterAmountBrandi(obj.brandi.length)
    setFilterAmountMalli(obj.malli.length)
    setFilterAmountMateriaali(obj.materiaali.length)
    setFilterAmountMateriaaliOminaisuus(obj.materiaaliOminaisuus.length)
    setFilterAmountPainoLuokka(obj.painoLuokka.length)
    setFilterAmountKaulaAukko(obj.kaulaAukko.length)
    setFilterAmountKoko(obj.koko.length)
    setFilterAmountKayttovinkki(obj.kayttovinkki.length)
    setFilterAmountVari(obj.vari.length)
    setFilterAmountTuoteRyhma(obj.tuoteRyhma.length)
    setFilterAmountAlaRyhma(obj.alaRyhma.length)
    setFilterAmountAjankohtaista(obj.ajankohtaista.length)
    setFilterAmountAmmattiryhma(obj.ammattiryhma.length)
    setFilterAmountStandardi(obj.standardi.length)
    updateFilter(obj)
  }

  // category muuttujan perusteella määritetään, mitkä filterit käytössä
  useEffect(() => {
    if (category) {
      switch (category[0]) {
        case '0':
        case '5':
        case '6':
        case '7':
        case 'e':
        case 'n':
          setGenreOn(true)
          break

        case '1':
        case '2':
        case '3':
        case '4':
          setGenreOn(false)
          break

        default:
          setGenreOn(false)
          break
      }

      switch (category) {
        case 'eco':
        case 'uutuus':
          return selectFilters(true, false, false, true, false, 'kaikki', true, true)

        case '0000':
        case '1000':
        case '2000':
        case '3000':
          return selectFilters(true, false, false, true, true, 'kaikki', true, true)

        // Paidat joissa genre valittu
        case '1100':
        case '2100':
        case '3100':
          return selectFilters(false, true, true, true, true, 'kaikki', true, true)

        // (Ylä-)Tuoteryhmät joissa ei genreä valittu
        case '0100':
        case '0200':
        case '0300':
        case '0400':
        case '0500':
        case '0600':
          return selectFilters(false, false, false, true, true, 'kaikki', true, true)

        // (Ylä-)Tuoteryhmät joissa genre valittu
        case '1200':
        case '1300':
        case '1400':
        case '1500':
        case '1600':
        case '2200':
        case '2300':
        case '2400':
        case '2500':
        case '2600':
        case '3200':
        case '3300':
        case '3400':
        case '3500':
        case '3600':
          return selectFilters(false, false, false, true, true, 'kaikki', true, true)

        // T-paidat, Collegepaidat, Neuleet (ei genreä)
        case '0101':
        case '0201':
        case '0104':
        case '0501':
          return selectFilters(false, true, true, true, true, 'paidat', false, true)

        // T-paidat, Collegepaidat, Neuleet (genrellä)
        case '1101':
        case '2101':
        case '3101':
        case '1201':
        case '2201':
        case '3201':
        case '1104':
        case '2104':
        case '3104':
        case '1501':
        case '2501':
        case '3501':
          return selectFilters(false, true, true, true, true, 'paidat', false, true)

        // Pikeet, Kauluspaidat, Collegehupparit, Vetoketjuhupparit, Collegetakit (ei genre)
        case '0102':
        case '0202':
        case '0203':
        case '0103':
        case '0204':
        case '0502':
        case '0507':
          return selectFilters(false, true, true, true, true, 'paidat', false, true)

        // Pikeet, Kauluspaidat, Collegehupparit, Vetoketjuhupparit, Collegetakit (genre)
        case '1102':
        case '2102':
        case '3102':
        case '1202':
        case '2202':
        case '3202':
        case '1203':
        case '2203':
        case '3203':
        case '1103':
        case '2103':
        case '3103':
        case '1204':
        case '2204':
        case '3204':
        case '1502':
        case '2502':
        case '3502':
        case '1507':
        case '2507':
        case '3507':
          return selectFilters(false, true, true, true, true, 'paidat', false, true)

        // Housut, Shortsit, Collegehousut ja trikoot (ei genre)
        case '0401':
        case '0402':
        case '0403':
        case '0503':
        case '0504':
          return selectFilters(false, false, false, true, true, 'alaosat', false, true)

        // Housut, Shortsit, Collegehousut ja trikoot (genre)
        case '1401':
        case '2401':
        case '3401':
        case '1402':
        case '2402':
        case '3402':
        case '1403':
        case '2403':
        case '3403':
        case '1503':
        case '2503':
        case '3503':
        case '1504':
        case '2504':
        case '3504':
          return selectFilters(false, false, false, true, true, 'alaosat', false, true)

        // Hameet ja mekot, Alusasut (ei genre)
        case '0404':
        case '0405':
          return selectFilters(false, false, false, true, false, 'alaosat', false, true)

        // Hameet ja mekot, Alusasut (genre)
        case '1404':
        case '2404':
        case '3404':
        case '1405':
        case '2405':
        case '3405':
          return selectFilters(false, false, false, true, false, 'alaosat', false, true)

        // Fleecetakit (ei genre)
        case '0301':
          return selectFilters(false, false, false, true, false, 'takit', false, true)

        // Fleecetakit (genre)
        case '1301':
        case '2301':
        case '3301':
          return selectFilters(false, false, false, true, false, 'takit', false, true)

        // Softshell, Tuuli ja kuoritakit, Topatut takit,
        // Yhdistelmätakit, Business- ja citytakit, Liivit (ei genre)
        case '0302':
        case '0303':
        case '0304':
        case '0305':
        case '0306':
        case '0307':
        case '0505':
          return selectFilters(false, false, false, true, true, 'takit', false, true)

        // Softshell, Tuuli ja kuoritakit, Topatut takit,
        // Yhdistelmätakit, Business- ja citytakit, Liivit (genre)
        case '1302':
        case '2302':
        case '3302':
        case '1303':
        case '2303':
        case '3303':
        case '1304':
        case '2304':
        case '3304':
        case '1305':
        case '2305':
        case '3305':
        case '1306':
        case '2306':
        case '3306':
        case '1307':
        case '2307':
        case '3307':
        case '1505':
        case '2505':
        case '3505':
          return selectFilters(false, false, false, true, true, 'takit', false, true)

        // Lippalakit, Pipot, Hatut (ei genre)
        case '0601':
          return selectFilters(false, false, false, true, true, 'asusteet', true, true)

        // Lippalakit, Pipot, Hatut (genre)
        case '1601':
        case '2601':
        case '3601':
          return selectFilters(false, false, false, true, true, 'asusteet', true, true)

        // Huivit ja kaulaliinat (ei genre)
        case '0603':
          return selectFilters(false, false, false, true, false, 'asusteet', false, true)

        // Käsineet, Solmiot ja vyöt, Sukat (ei genre)
        case '0602':
        case '0604':
        case '0605':
          return selectFilters(false, false, false, true, false, 'asusteet', false, true)

        // Käsineet, Huivit ja kaulaliinat, Solmiot ja vyöt, Sukat (genre)
        case '1602':
        case '2602':
        case '3602':
        case '1603':
        case '2603':
        case '3603':
        case '1604':
        case '2604':
        case '3604':
        case '1605':
        case '2605':
        case '3605':
          return selectFilters(false, false, false, true, false, 'asusteet', false, true)

        // Sadevarusteet, Peitot ja muut oheistuotteet, Kengät (ei genre)
        case '0607':
        case '0609':
        case '0610':
          return selectFilters(false, false, false, true, true, 'oheistuotteet', false, true)

        // Essut, Sadevarusteet, Kylpytuotteet, Peitot ja muut oheistuotteet (ei genre)
        case '0606':
        case '0608':
        case '0506':
          return selectFilters(false, false, false, true, true, 'oheistuotteet', false, true)

        // Essut, Sadevarusteet, Kylpytuotteet, Peitot ja muut oheistuotteet, Kengät (genre)
        case '1606':
        case '2606':
        case '3606':
        case '1607':
        case '2607':
        case '3607':
        case '1608':
        case '2608':
        case '3608':
        case '1609':
        case '2609':
        case '3609':
        case '1610':
        case '2610':
        case '3610':
        case '1506':
        case '2506':
        case '3506':
          return selectFilters(false, false, false, true, true, 'oheistuotteet', false, true)

        // Laukut
        case '4000':
          return selectFilters(false, false, false, true, false, 'oheistuotteet', true, true)

        case '4001':
        case '4002':
        case '4003':
        case '4004':
        case '4005':
        case '4006':
        case '4007':
        case '4008':
        case '4009':
        case '4010':
        case '4011':
          return selectFilters(false, false, false, true, false, 'oheistuotteet', false, true)

        // Työvaatteet
        case '5000':
          return selectFilters(true, true, false, true, true, 'tyovaatteet', true, true, true, true)

        // Työvaatteet - T-paidat, Pikeet
        case '5001':
        case '5003':
          return selectFilters(false, true, true, true, true, 'tyovaatteet', false, true, true, true)

        // Työvaatteet - Pikeet, Col.hupparit, V.ketjuhupparit,
        // Kauluspaidat, Collegetakit, Takit ja liivit
        case '5002':
        case '5004':
        case '5005':
        case '5007':
        case '5008':
        case '5009':
          return selectFilters(false, true, false, true, true, 'tyovaatteet', false, true, true, true)

        // Työvaatteet - Työhousut
        case '5006':
          return selectFilters(false, false, false, true, true, 'tyovaatteet', false, true, true, true)

        // Työvaatteet - Oheistuotteet
        case '5010':
          return selectFilters(false, false, false, true, false, 'tyovaatteet', false, true, true, true)

        // Työvaatteet pääkategoriat (12/2022 uudistuksen mukana tulleet)
        case '5100':
        case '5200':
        case '5300':
        case '5301':
        case '5400':
        case '5401':
        case '5500':
        case '5501':
        case '5502':
        case '5503':
        case '5504':
          return selectFilters(true, true, false, true, true, 'tyovaatteet', true, true, true, true)
          
        // Työvaatteet (ei tuoteryhmä filtteriä)
        case '5101':
        case '5102':
        case '5103':
        case '5104':
        case '5105':
        case '5201':
        case '5202':
        case '5203':
        case '5204':
        case '5206':
        case '5302':
        case '5305':
        case '5402':
        case '5405':
          return selectFilters(false, true, false, true, true, 'tyovaatteet', true, true, true, true)

        // Työvaatteet (ei tuoteryhmä eikä alaryhmä filtteriä)
        case '5205':
        case '5303':
        case '5304':
        case '5403':
        case '5404':
        case '5505':
          return selectFilters(false, true, false, true, true, 'tyovaatteet', false, true, true, true)

        // Tuotemerkit
        case '6001':
        case '6002':
        case '6003':
        case '6004':
        case '6005':
        case '6006':
        case '6007':
        case '6008':
        case '6009':
        case '6010':
        case '6011':
        case '6012':
        case '6013':
        case '6014':
        case '6015':
        case '6016':
        case '6017':
        case '6018':
        case '6019':
        case '6020':
        case '6021':
        case '6022':
        case '6023':
        case '6024':
          return selectFilters(true, false, false, false, true, 'kaikki', true, true)

        default:
          selectFilters(true, false, false, true, false, 'kaikki', false, false)
          break
      }
    }

    if (source === 'outlet' || source === 'search') {
      selectFilters(true, false, false, true, false, 'kaikki', true, true)
      setGenreOn(true)
    }

    // eslint-disable-next-line
  }, [category, source])

  const updateFilterURL = (key, value) => {
    const theValue = value.replace('&', '')
    const filterState = filter[key].map((el) => el.replace('&', ''))
    if (filterState.includes(theValue)) {
      const index = filterState.indexOf(theValue)
      filterState.splice(index, 1)
    } else {
      filterState.push(theValue)
    }
    const newValue = filterState.map((el) => el.replace('&', ''))
    const search = window.location.search
    const q = parseQuery(search)
    const q_filter = JSON.parse(q.filter)

    if (!newValue.length) {
      delete q_filter[key]
    } else {
      q_filter[key] = newValue
    }

    // Tarkistus, poistettiinko tuote- tai alaryhmä valinta ennen lukittavia alaosioita
    if (tuoteRyhmaOn) {
      if (!q_filter.tuoteRyhma || !q_filter.tuoteRyhma.length) {
        if (
          (q_filter.alaRyhma && q_filter.alaRyhma.length) ||
          (q_filter.istutus && q_filter.istutus.length) ||
          (q_filter.kaulaAukko && q_filter.kaulaAukko.length) ||
          (q_filter.koko && q_filter.koko.length) ||
          (q_filter.malli && q_filter.malli.length) ||
          (q_filter.materiaali && q_filter.materiaali.length) ||
          (q_filter.materiaaliOminaisuus && q_filter.materiaaliOminaisuus.length) ||
          (q_filter.painoLuokka && q_filter.painoLuokka.length)
        ) {
          delete q_filter.alaRyhma
          delete q_filter.istutus
          delete q_filter.kaulaAukko
          delete q_filter.koko
          delete q_filter.malli
          delete q_filter.materiaali
          delete q_filter.materiaaliOminaisuus
          delete q_filter.painoLuokka
        }
      }
    } else if (alaRyhmaOn) {
      if (!q_filter.alaRyhma || !q_filter.alaRyhma.length) {
        if (
          (q_filter.istutus && q_filter.istutus.length) ||
          (q_filter.kaulaAukko && q_filter.kaulaAukko.length) ||
          (q_filter.koko && q_filter.koko.length) ||
          (q_filter.malli && q_filter.malli.length) ||
          (q_filter.materiaali && q_filter.materiaali.length) ||
          (q_filter.materiaaliOminaisuus && q_filter.materiaaliOminaisuus.length) ||
          (q_filter.painoLuokka && q_filter.painoLuokka.length)
        ) {
          delete q_filter.istutus
          delete q_filter.kaulaAukko
          delete q_filter.koko
          delete q_filter.malli
          delete q_filter.materiaali
          delete q_filter.materiaaliOminaisuus
          delete q_filter.painoLuokka
        }
      }
    }

    q.filter = JSON.stringify(q_filter)
    switch (source) {
      case 'search':
        navigate(
          `/${urlLang}/${source}/${encodeURIComponent(keyword.replace(/%/g, '~~pct~~'))}?page=${q.page}&sort=${
            q.sort
          }&price=${q.price}&filter=${q.filter}`
        )
        break
      case 'browse':
      case 'campaign':
        navigate(
          `/${urlLang}/${source}/${category}?page=${q.page}&sort=${q.sort}&price=${q.price}&filter=${q.filter}`
        )
        break
      case 'outlet':
        navigate(`/${urlLang}/${source}?page=${q.page}&sort=${q.sort}&price=${q.price}&filter=${q.filter}`)
        break
      default:
        navigate(`/${urlLang}`)
    }
  }

  const tuoteRyhmaHasSelection = () => {
    if (filter?.tuoteRyhma?.length) return true
    else return false
  }

  const alaRyhmaHasSelection = () => {
    if (filter?.alaRyhma?.length) return true
    else return false
  }

  const removeOptionsWithZeroHits = (opt) => {
    if (tuoteRyhmaHasSelection() && alaRyhmaHasSelection()) {
      return defaultList
        .filter(
          (el) =>
          filter.tuoteRyhma.includes(el.tuoteRyhma) && filter.alaRyhma.includes(el.alaRyhma)
        )
        .map((el) => el[opt])
    } else if (tuoteRyhmaHasSelection()) {
      return defaultList
        .filter((el) => filter.tuoteRyhma.includes(el.tuoteRyhma))
        .map((el) => el[opt])
    } else if (alaRyhmaHasSelection()) {
      return defaultList
        .filter((el) => filter.alaRyhma.includes(el.alaRyhma))
        .map((el) => el[opt])
    } else {
      return defaultList.map((el) => el[opt])
    }
  }

  const combineArraysAndRemoveDuplicates = (items) => {
    if (items?.length && Array.isArray(items[0])) {
      items = [].concat.apply([], items)
    }
    const uniqueItems = [...new Set(items)]
    if (uniqueItems.includes('')) {
      const index = uniqueItems.indexOf('')
      uniqueItems.splice(index, 1)
    }
    return uniqueItems
  }

  const renderFilterSelectOnlyOneUI = (opt, setCheckboxes, setTags) => {
    if ((category || keyword) && itemList) {
      const allOptions = itemList.map((el) => el[opt])
      const uniqueOptions = [...new Set(allOptions)]
      if (uniqueOptions.includes('')) {
        const index = uniqueOptions.indexOf('')
        uniqueOptions.splice(index, 1)
      }
      if (uniqueOptions.length) {
        const checkboxes = uniqueOptions
          .sort()
          .map((id) => (
            <Form.Check
              key={id}
              name={id}
              type='checkbox'
              label={`${getCategoryTranslationByFinnishString(id, lang.n)}`}
              // label={`${id} (${defaultList.filter((el) => el[opt] === id).length})`}
              checked={filter[opt].includes(id)}
              onChange={() => updateFilterURL(opt, id)}
            />
          ))
        setCheckboxes(checkboxes)
      }

      if (filter[opt].length) {
        const tags = filter[opt].sort().map((id) => (
          <div className='tagContainer' key={id} value={filter[opt].includes(id)}>
            <Badge pill variant='primary'>
              <span>{getCategoryTranslationByFinnishString(id, lang.n)}</span>
              <button className='tagButton' onClick={() => updateFilterURL(opt, id)}>
                x
              </button>
            </Badge>
          </div>
        ))
        setTags(tags)
      } else {
        setTags(null)
      }
    }
  }

  // Ajankohtaista filtterin renderöinti funktio
  const renderFilterUI = (opt, setCheckboxes, setTags) => {
    if ((category || keyword) && defaultList) {
      const optionsWithHits = removeOptionsWithZeroHits(opt)
      const uniquesWithHits = combineArraysAndRemoveDuplicates(optionsWithHits)
      if (uniquesWithHits?.length) {
        const checkboxes = uniquesWithHits
          .sort()
          .map((id) => (
            <Form.Check
              key={id}
              name={id}
              type='checkbox'
              label={`${getFilterTranslationByFinnishString(id, lang.n)}`}
              // label={`${id} (${countAmountForRender(id)})`}
              checked={filter[opt].includes(id)}
              onChange={() => updateFilterURL(opt, id)}
            />
          ))
        setCheckboxes(checkboxes)
      } else {
        setCheckboxes(null)
      }

      if (filter[opt].length) {
        const tags = filter[opt].sort().map((id) => (
          <div className='tagContainer' key={id} value={filter[opt].includes(id)}>
            <Badge pill variant='primary'>
              <span>{getFilterTranslationByFinnishString(id, lang.n)}</span>
              <button className='tagButton' onClick={() => updateFilterURL(opt, id)}>
                x
              </button>
            </Badge>
          </div>
        ))
        setTags(tags)
      } else {
        setTags(null)
      }
    }
  }

  const renderKayttovinkkiUI = () => {
    if ((category || keyword) && defaultList) {
      const kayttovinkkiAll = defaultList.map((el) => el.kayttovinkki)
      const kayttovinkkiTrueValues = kayttovinkkiAll.map((obj) =>
        Object.keys(obj).filter(function (x) {
          return obj[x] !== false
        })
      )
      const kayttovinkkiAllIntoOne = [].concat.apply([], kayttovinkkiTrueValues)
      const kayttovinkkiUniq = [...new Set(kayttovinkkiAllIntoOne)]
      if (kayttovinkkiUniq.includes('')) {
        const index = kayttovinkkiUniq.indexOf('')
        kayttovinkkiUniq.splice(index, 1)
      }
      if (kayttovinkkiUniq.length) {
        const cbKayttovinkki = kayttovinkkiUniq
          .sort()
          .map((id) => (
            <Form.Check
              key={id}
              name={id}
              type='checkbox'
              label={`${getFilterTranslationByFinnishString(id, lang.n)}`}
              // label={`${id} (${defaultList.filter((el) => el.kayttovinkki[id]).length})`}
              checked={filter.kayttovinkki.includes(id)}
              onChange={() => updateFilterURL('kayttovinkki', id)}
            />
          ))
        setJsxKayttovinkki(cbKayttovinkki)
      } else {
        setJsxKayttovinkki(null)
      }
      if (filter.kayttovinkki.length) {
        const tags = filter.kayttovinkki.sort().map((id) => (
          <div className='tagContainer' key={id} value={filter.kayttovinkki.includes(id)}>
            <Badge pill variant='primary'>
              <span>{getFilterTranslationByFinnishString(id, lang.n)}</span>
              <button className='tagButton' onClick={() => updateFilterURL('kayttovinkki', id)}>
                x
              </button>
            </Badge>
          </div>
        ))
        setTagKayttovinkki(tags)
      } else {
        setTagKayttovinkki(null)
      }
    }
  }

  const renderKokoUI = () => {
    if ((category || keyword) && defaultList) {
      const optionsWithHits = removeOptionsWithZeroHits('koko')
      const uniquesWithHits = combineArraysAndRemoveDuplicates(optionsWithHits)
      if (uniquesWithHits?.length) {
        const cbKoko = uniquesWithHits
          .sort((a, b) => (parseInt(a) > parseInt(b) ? 1 : -1))
          .map((id) => (
            <Form.Check
              key={id}
              name={id}
              type='checkbox'
              label={`${getSize(id)}`}
              // label={`${getSize(id)} (${defaultList.filter((el) => el.koko.includes(id)).length})`}
              checked={filter.koko.includes(id)}
              onChange={() => updateFilterURL('koko', id)}
            />
          ))
        setJsxKoko(cbKoko)
      } else {
        setJsxKoko(null)
      }

      if (filter.koko.length) {
        const tags = filter.koko.sort().map((id) => (
          <div className='tagContainer' key={id} value={filter.koko.includes(id)}>
            <Badge pill variant='primary'>
              <span>{getSize(id)}</span>
              <button className='tagButton' onClick={() => updateFilterURL('koko', id)}>
                x
              </button>
            </Badge>
          </div>
        ))
        setTagKoko(tags)
      } else {
        setTagKoko(null)
      }
    }
  }

  const renderAmmattiryhmaUI = () => {
    if ((category || keyword) && defaultList) {
      let optionsWithHits = removeOptionsWithZeroHits('ammattiryhma')
      optionsWithHits = combineArraysAndRemoveDuplicates(optionsWithHits)
      if (optionsWithHits.length) {
        const cbAmmattiryhma = optionsWithHits
          .sort()
          .map((id) => (
            <Form.Check
              key={id}
              name={id}
              type='checkbox'
              label={`${getFilterTranslationByFinnishString(getProductAmmattiRyhmaName(id), lang.n)}`}
              // label={`${getProductAmmattiRyhmaName(id)} (${defaultList?.filter((el) => el.ammattiryhma?.includes(id)).length})`}
              checked={filter.ammattiryhma?.includes(id)}
              onChange={() => updateFilterURL('ammattiryhma', id)}
            />
          ))
        setJsxAmmattiryhma(cbAmmattiryhma)
      } else {
        setJsxAmmattiryhma(null)
      }

      if (filter.ammattiryhma.length) {
        const tags = filter.ammattiryhma.sort().map((id) => (
          <div className='tagContainer' key={id} value={filter.ammattiryhma.includes(id)}>
            <Badge pill variant='primary'>
              <span>{getFilterTranslationByFinnishString(getProductAmmattiRyhmaName(id), lang.n)}</span>
              <button className='tagButton' onClick={() => updateFilterURL('ammattiryhma', id)}>
                x
              </button>
            </Badge>
          </div>
        ))
        setTagAmmattiryhma(tags)
      } else {
        setTagAmmattiryhma(null)
      }
    }
  }

  const renderStandardiUI = () => {
    if ((category || keyword) && defaultList) {
      let optionsWithHits = removeOptionsWithZeroHits('standardi')
      optionsWithHits = combineArraysAndRemoveDuplicates(optionsWithHits)
      if (optionsWithHits.length) {
        const cbStandardi = optionsWithHits
          .sort()
          .map((id) => (
            <Form.Check
              key={id}
              name={id}
              type='checkbox'
              label={`${getFilterTranslationByFinnishString(getProductStandardName(id), lang.n)}`}
              // label={`${getProductStandardName(id)} (${defaultList?.filter((el) => el.standardi?.includes(id)).length})`}
              checked={filter.standardi?.includes(id)}
              onChange={() => updateFilterURL('standardi', id)}
            />
          ))
        setJsxStandardi(cbStandardi)
      } else {
        setJsxStandardi(null)
      }

      if (filter.standardi.length) {
        const tags = filter.standardi.sort().map((id) => (
          <div className='tagContainer' key={id} value={filter.standardi.includes(id)}>
            <Badge pill variant='primary'>
              <span>{getFilterTranslationByFinnishString(getProductStandardName(id), lang.n)}</span>
              <button className='tagButton' onClick={() => updateFilterURL('standardi', id)}>
                x
              </button>
            </Badge>
          </div>
        ))
        setTagStandardi(tags)
      } else {
        setTagStandardi(null)
      }
    }
  }

  const renderMateriaaliOminaisuusUI = () => {
    if ((category || keyword) && defaultList) {
      let optionsWithHits = removeOptionsWithZeroHits('materiaaliOminaisuus')
      optionsWithHits = combineArraysAndRemoveDuplicates(optionsWithHits)
      if (optionsWithHits?.length) {
        const cbMateriaaliOminaisuus = optionsWithHits
          .sort()
          .map((id) => (
            <Form.Check
              key={id}
              name={id}
              type='checkbox'
              label={`${getFilterTranslationByFinnishString(getMateriaaliOminaisuusName(id), lang.n)}`}
              checked={filter.materiaaliOminaisuus?.includes(id)}
              onChange={() => updateFilterURL('materiaaliOminaisuus', id)}
            />
          ))
        setJsxMateriaaliOminaisuus(cbMateriaaliOminaisuus)
      } else {
        setJsxMateriaaliOminaisuus(null)
      }

      if (filter.materiaaliOminaisuus.length) {
        const tags = filter.materiaaliOminaisuus.sort().map((id) => (
          <div className='tagContainer' key={id} value={filter.materiaaliOminaisuus.includes(id)}>
            <Badge pill variant='primary'>
              <span>{getFilterTranslationByFinnishString(getMateriaaliOminaisuusName(id), lang.n)}</span>
              <button className='tagButton' onClick={() => updateFilterURL('materiaaliOminaisuus', id)}>
                x
              </button>
            </Badge>
          </div>
        ))
        setTagMateriaaliOminaisuus(tags)
      } else {
        setTagMateriaaliOminaisuus(null)
      }
    }
  }

  // const renderMalliUI = () => {
  //   if ((category || keyword) && defaultList) {
  //     let optionsWithHits = removeOptionsWithZeroHits('malli')
  //     optionsWithHits = combineArraysAndRemoveDuplicates(optionsWithHits)
  //     if (optionsWithHits.length) {
  //       const cbMalli = optionsWithHits
  //         .sort()
  //         .map((id) => (
  //           <Form.Check
  //             key={id}
  //             name={id}
  //             type='checkbox'
  //             label={`${id}`}
  //             // label={`${id} (${defaultList.filter((el) => el.malli.includes(id)).length})`}
  //             checked={filter.malli.includes(id)}
  //             onChange={() => updateFilterURL('malli', id)}
  //           />
  //         ))
  //       setJsxMalli(cbMalli)
  //     } else {
  //       setJsxMalli(null)
  //     }

  //     if (filter.malli.length) {
  //       const tags = filter.malli.sort().map((id) => (
  //         <div className='tagContainer' key={id} value={filter.malli.includes(id)}>
  //           <Badge pill variant='primary'>
  //             <span>{id}</span>
  //             <button className='tagButton' onClick={() => updateFilterURL('malli', id)}>
  //               x
  //             </button>
  //           </Badge>
  //         </div>
  //       ))
  //       setTagMalli(tags)
  //     } else {
  //       setTagMalli(null)
  //     }
  //   }
  // }

  const renderVariUI = () => {
    if ((category || keyword) && defaultList) {
      const optionsWithHits = removeOptionsWithZeroHits('variRyhmat')
      const uniquesWithHits = combineArraysAndRemoveDuplicates(optionsWithHits)
      if (uniquesWithHits?.length) {
        const cbVari = uniquesWithHits.sort().map((id) => (
          <Form.Check
            key={id}
            name={id}
            type='checkbox'
            label={
              <p>
                <span className='colorSample' id={`sample${id}`} />
                {`${getFilterTranslationByFinnishString(id, lang.n)}`}
                {/* {`${id} (${defaultList.filter((el) => el.variRyhmat.includes(id)).length})`} */}
              </p>
            }
            checked={filter.vari.includes(id)}
            onChange={() => updateFilterURL('vari', id)}
          />
        ))
        setJsxVari(cbVari)
      } else {
        setJsxVari(null)
      }

      if (filter.vari.length) {
        const tags = filter.vari.sort().map((id) => (
          <div className='tagContainer' key={id} value={filter.vari.includes(id)}>
            <Badge pill variant='primary'>
              <span>{getFilterTranslationByFinnishString(id, lang.n)}</span>
              <button className='tagButton' onClick={() => updateFilterURL('vari', id)}>
                x
              </button>
            </Badge>
          </div>
        ))
        setTagVari(tags)
      } else {
        setTagVari(null)
      }
    }
  }

  const renderAjankohtaistaUI = () => {
    if ((category || keyword) && defaultList) {
      const countEco = defaultList.filter((el) => el.eco).length
      const countOutlet = defaultList.filter((el) => el.isOutlet).length
      const countUutuus = defaultList.filter((el) => el.uutuus).length
      const options = []

      if (countEco) options.push({ value: 'eco', label: 'Eco' })
      if (countOutlet) options.push({ value: 'isOutlet', label: 'Outlet' })
      if (countUutuus) options.push({ value: 'uutuus', label: 'Uutuus' })

      if (options && options.length) {
        const checkBoxes = options.map((cb) => (
          <Form.Check
            key={cb.value}
            name={cb.value}
            type='checkbox'
            label={`${getFilterTranslationByFinnishString(cb.label, lang.n)}`}
            // label={`${cb.label} (${defaultList.filter((el) => el[cb.value] === true).length})`}
            checked={filter.ajankohtaista.includes(cb.value)}
            onChange={() => updateFilterURL('ajankohtaista', cb.value)}
          />
        ))
        setJsxAjankohtaista(checkBoxes)
      } else {
        setJsxAjankohtaista(null)
      }

      if (filter.ajankohtaista.length) {
        function capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1)
        }

        const tags = filter.ajankohtaista.sort().map((id) => (
          <div className='tagContainer' key={id} value={filter.ajankohtaista.includes(id)}>
            <Badge pill variant='primary'>
              <span>
                {id === 'isOutlet' ?
                getFilterTranslationByFinnishString('Outlet', lang.n)
                :
                getFilterTranslationByFinnishString(capitalizeFirstLetter(id), lang.n)}
              </span>
              <button className='tagButton' onClick={() => updateFilterURL('ajankohtaista', id)}>
                x
              </button>
            </Badge>
          </div>
        ))
        setTagAjankohtaista(tags)
      } else {
        setTagAjankohtaista(null)
      }
    }
  }

  // Renderöidään filtterit
  useEffect(() => {
    renderFilterUI('genre', setJsxGenre, setTagGenre)
    renderFilterUI('istutus', setJsxIstutus, setTagIstutus)
    renderFilterUI('brandi', setJsxBrandi, setTagBrandi)
    renderFilterUI('painoLuokka', setJsxPainoluokka, setTagPainoLuokka)
    renderFilterUI('kaulaAukko', setJsxKaulaAukko, setTagKaulaAukko)
    renderFilterUI('materiaali', setJsxMateriaali, setTagMateriaali)
    renderMateriaaliOminaisuusUI()
    renderFilterUI('malli', setJsxMalli, setTagMalli)
    renderFilterUI('ammattiryhma', setJsxAmmattiryhma, setTagAmmattiryhma)
    renderFilterUI('standardi', setJsxStandardi, setTagStandardi)
    renderFilterSelectOnlyOneUI('tuoteRyhma', setJsxTuoteryhma, setTagTuoteRyhma)
    renderFilterSelectOnlyOneUI('alaRyhma', setJsxAlaryhma, setTagAlaryhma)
    renderAjankohtaistaUI()
    renderKayttovinkkiUI()
    renderKokoUI()
    renderVariUI()
    renderAmmattiryhmaUI()
    renderStandardiUI()

    // eslint-disable-next-line
  }, [category, keyword, defaultList, itemList, filter, utils, lang.id])

  const handlePriceChange = (value, id) => {
    const newValues = hintaValue
    let newVal

    if (value === '') newVal = 0
    else newVal = parseInt(value)

    if (Number.isNaN(newVal)) return
    if (newVal < 0) newVal = 0
    if (newVal > 999) newVal = 999
    newValues[id] = newVal

    const search = window.location.search
    const q = parseQuery(search)

    switch (source) {
      case 'search':
        navigate(
          `/${urlLang}/${source}/${encodeURIComponent(keyword.replace(/%/g, '~~pct~~'))}?page=${q.page}&sort=${
            q.sort
          }&price=${newValues}&filter=${q.filter}`
        )
        break
      case 'browse':
      case 'campaign':
        navigate(
          `/${urlLang}/${source}/${category}?page=${q.page}&sort=${q.sort}&price=${newValues}&filter=${q.filter}`
        )
        break
      case 'outlet':
        navigate(`/${urlLang}/${source}?page=${q.page}&sort=${q.sort}&price=${newValues}&filter=${q.filter}`)
        break
      default:
        navigate(`/${urlLang}`)
    }
  }

  // Varsinainen filter useEffect -> ajaa [].filter() methodia tuotteisiin rajausten perusteella
  useEffect(() => {
    if (filter && hintaValue && defaultList && defaultList.length) {
      // Alustetaan filteredList ja UI muuttujat
      let filteredList = defaultList
      let genreF = []
      let istutusF = []
      let brandiF = []
      let malliF = []
      let materiaaliF = []
      let materiaaliOminaisuusF = []
      let painoLuokkaF = []
      let kaulaAukkoF = []
      let kokoF = []
      let kayttovinkkiF = []
      let variF = []
      let tuoteRyhmaF = []
      let alaRyhmaF = []
      let ajankohtaistaF = []
      let ammattiryhmaF = []
      let standardiF = []

      // ************** FILTERIT ALKAA **************
      // Filter: genre
      if (genreOn) {
        genreF = filter.genre
        if (genreF.length) filteredList = filteredList.filter((el) => genreF.includes(el.genre))
      }

      // Filter: istutus
      if (istutusOn) {
        istutusF = filter.istutus
        if (istutusF.length)
          filteredList = filteredList.filter((el) => istutusF.includes(el.istutus))
      }

      // Filter: brändi
      if (brandiOn) {
        brandiF = filter.brandi
        if (brandiF.length) filteredList = filteredList.filter((el) => brandiF.includes(el.brandi))
      }

      if (malliOn) {
        malliF = filter.malli
        if (malliF.length) {
          const includesEveryModel = (models) => {
          return malliF.every((m) => models.includes(m))
          }
          filteredList = filteredList.filter((tuote) => includesEveryModel(tuote.malli))
        }
      }

      // Filter: materiaali
      if (materiaaliOn) {
        materiaaliF = filter.materiaali
        if (materiaaliF.length)
          filteredList = filteredList.filter((el) => materiaaliF.includes(el.materiaali))
      }

      // Filter: materiaaliOminaisuus
      if (materiaaliOminaisuusOn) {
        materiaaliOminaisuusF = filter.materiaaliOminaisuus
        if (materiaaliOminaisuusF.length) {
          filteredList = filteredList.filter((el) => {
            return materiaaliOminaisuusF.every((ominaisuus) => el.materiaaliOminaisuus.includes(ominaisuus))
          })
        }
      }

      // Filter: painoluokka
      if (painoLuokkaOn) {
        painoLuokkaF = filter.painoLuokka
        if (painoLuokkaF.length)
          filteredList = filteredList.filter((el) => painoLuokkaF.includes(el.painoLuokka))
      }

      // Filter: kaula-aukko
      if (kaulaAukkoOn) {
        kaulaAukkoF = filter.kaulaAukko
        if (kaulaAukkoF.length)
          filteredList = filteredList.filter((el) => kaulaAukkoF.includes(el.kaulaAukko))
      }

      // Filter: koko
      if (kokoOn) {
        kokoF = filter.koko
        if (kokoF.length) {
          const includesEverySize = (sizes) => {
          return kokoF.every((el) => sizes.includes(el))
          }
          filteredList = filteredList.filter((tuote) => includesEverySize(tuote.koko))
       }
      }

      // Filter: ammattiryhma
      if (ammattiryhmaOn) {
        ammattiryhmaF = filter.ammattiryhma
        if (ammattiryhmaF.length) {
          const includesEveryAmmattiryhma = (ars) => {
          return ammattiryhmaF.every((el) => ars.includes(el))
          }
          filteredList = filteredList.filter((tuote) => includesEveryAmmattiryhma(tuote.ammattiryhma))
        }
      }

      // Filter: standardi
      if (standardiOn) {
        standardiF = filter.standardi
        if (standardiF.length) {
          const includesEveryStandardi = (standards) => {
          return standardiF.every((el) => standards.includes(el))
          }
          filteredList = filteredList.filter((tuote) => includesEveryStandardi(tuote.standardi))
        }
      }

      // Filter: käyttövinkki
      if (kayttovinkkiOn) {
        kayttovinkkiF = filter.kayttovinkki
        if (kayttovinkkiF.length) {
          filteredList = filteredList.filter((el) =>
            kayttovinkkiF.some((item) => el.kayttovinkki[item] === true)
          )
        }
      }

      // Filter: väri
      if (variOn) {
        variF = filter.vari
        if (variF.length) {
          filteredList = filteredList.filter((el) =>
            variF.some((item) => el.variRyhmat.includes(item))
          )
        }
      }

      // Filter: koko+väri variaatio kombinaatiot
      if (kokoOn && variOn && filter.koko?.length && filter.vari?.length) {
        kokoF = filter.koko
        variF = filter.vari
        const newFilteredList = []

        for (let product of filteredList) {
          const variationsWithSelectedColorAndSize = product.variaatiot.filter(variation => variation.aktiivinen && variF.includes(variation.variRyhma) && kokoF.includes(variation.koko))
          if (variationsWithSelectedColorAndSize.length) {
            const variationSizes = variationsWithSelectedColorAndSize.map(variation => variation.koko)
            const productHasAllWantedSizes = (arr, target) => target.every(v => arr.includes(v))
            if (productHasAllWantedSizes(variationSizes, kokoF)) {
              newFilteredList.push(product)
            }
          }
        }

        filteredList = newFilteredList
      }

      // Filter: tuoteryhmä
      if (tuoteRyhmaOn) {
        tuoteRyhmaF = filter.tuoteRyhma
        if (tuoteRyhmaF.length)
          filteredList = filteredList.filter((el) => tuoteRyhmaF.includes(el.tuoteRyhma))
      }

      if (alaRyhmaOn) {
        alaRyhmaF = filter.alaRyhma
        if (alaRyhmaF.length)
          filteredList = filteredList.filter((el) => alaRyhmaF.includes(el.alaRyhma))
      }

      // Filter: ajankohtaista
      if (ajankohtaistaOn) {
        ajankohtaistaF = filter.ajankohtaista

        const compareAjankohtaista = (tuote) => {
          const tuoteKoodi = tuote.tuoteKoodi
          const isOutlet = tuote.isOutlet
          const keys = ['eco', 'isOutlet', 'uutuus']
          const filtered = keys.filter((key) => {
            return tuote[key]
          })

          const match = filtered.some((el) => ajankohtaistaF.includes(el))
          if (!match) {
            const count = filteredList.filter((el) => el.tuoteKoodi === tuoteKoodi).length
            if (count > 1) {
              filteredList = filteredList.filter(
                (el) =>
                  el.tuoteKoodi !== tuoteKoodi ||
                  (el.tuoteKoodi === tuoteKoodi && el.isOutlet !== isOutlet)
              )
            } else {
              filteredList = filteredList.filter((el) => el.tuoteKoodi !== tuoteKoodi)
            }
          }
        }

        if (ajankohtaistaF.length) {
          filteredList.forEach(compareAjankohtaista)
        }
      }

      // Filter: hinta
      const compareHinta = (tuote) => {
        const tuoteKoodi = tuote.tuoteKoodi
        const hinta = tuote.startingPrice
        const ylaRaja = !hintaValue[1] ? 9999 : hintaValue[1]
        let outOfRange = hinta < hintaValue[0] || hinta > ylaRaja
        if (outOfRange) {
          filteredList = filteredList.filter((el) => el.tuoteKoodi !== tuoteKoodi)
        }
      }

      filteredList.forEach(compareHinta)

      // ************** FILTERIT LOPPUU **************
      // Jos ei yhtään rajausta valittuna --> laitetaan takaisin kaikki tuotteet
      // Muussa tapauksessa laitetaan rajatut tulokset itemListiin
      if (
        !genreF.length &&
        !istutusF.length &&
        !brandiF.length &&
        !malliF.length &&
        !materiaaliF.length &&
        !materiaaliOminaisuusF.length &&
        !painoLuokkaF.length &&
        !kaulaAukkoF.length &&
        !kokoF.length &&
        !kayttovinkkiF.length &&
        !variF.length &&
        !tuoteRyhmaF.length &&
        !alaRyhmaF.length &&
        !ammattiryhmaF.length &&
        !standardiF.length &&
        !ajankohtaistaF.length &&
        hintaValue[0] === 0 &&
        hintaValue[1] === 0
      ) {
        setItemList(defaultList)
      } else {
        setItemList(filteredList)
      }
    }
    // eslint-disable-next-line
  }, [filter, updateFilter, hintaValue, defaultList])

  // Kun sivu ladataan ensimmäisen kerran, tarkistetaan onko
  // filtereitä jo valittu. Jos on niin avataan niiden valikot.
  useEffect(() => {
    const search = window.location.search
    const q = parseQuery(search)
    if (q.filter) {
      const parsedFilter = JSON.parse(q.filter)
      const keys = Object.keys(parsedFilter)
      if (keys.length) {
        if (
          keys.includes('tuoteRyhma') &&
          parsedFilter.tuoteRyhma &&
          parsedFilter.tuoteRyhma.length
        )
          toggleHideTuoteRyhma(false)
        if (keys.includes('alaRyhma') && parsedFilter.alaRyhma && parsedFilter.alaRyhma.length)
          toggleHideAlaRyhma(false)
        if (keys.includes('istutus') && parsedFilter.istutus && parsedFilter.istutus.length)
          toggleHideIstutus(false)
        if (
          keys.includes('kaulaAukko') &&
          parsedFilter.kaulaAukko &&
          parsedFilter.kaulaAukko.length
        )
          toggleHideKaulaAukko(false)
        if (keys.includes('koko') && parsedFilter.koko && parsedFilter.koko.length)
          toggleHideKoko(false)
        if (keys.includes('ammattiryhma') && parsedFilter.ammattiryhma && parsedFilter.ammattiryhma.length)
          toggleHideAmmattiryhma(false)
        if (keys.includes('standardi') && parsedFilter.standardi && parsedFilter.standardi.length)
          toggleHideStandardi(false)
        if (keys.includes('malli') && parsedFilter.malli && parsedFilter.malli.length)
          toggleHideMalli(false)
        if (
          keys.includes('materiaali') &&
          parsedFilter.materiaali &&
          parsedFilter.materiaali.length
        )
          toggleHideMateriaali(false)
        if (
          keys.includes('materiaaliOminaisuus') &&
          parsedFilter.materiaaliOminaisuus &&
          parsedFilter.materiaaliOminaisuus.length
        )
          toggleHideMateriaaliOminaisuus(false)
        if (
          keys.includes('painoLuokka') &&
          parsedFilter.painoLuokka &&
          parsedFilter.painoLuokka.length
        )
          toggleHidePainoLuokka(false)
        if (keys.includes('ajankohtaista') && parsedFilter.ajankohtaista && parsedFilter.ajankohtaista.length)
          toggleHideAjankohtaista(false)
        if (
          keys.includes('kayttovinkki') &&
          parsedFilter.kayttovinkki &&
          parsedFilter.kayttovinkki.length
        )
          toggleHideKayttovinkki(false)
        if (keys.includes('brandi') && parsedFilter.brandi && parsedFilter.brandi.length)
          toggleHideBrandi(false)
        if (keys.includes('genre') && parsedFilter.genre && parsedFilter.genre.length)
          toggleHideGenre(false)
        if (keys.includes('vari') && parsedFilter.vari && parsedFilter.vari.length)
          toggleHideVari(false)
      }
    }
    // eslint-disable-next-line
  }, [])

  const sumOfFilter =
    filterAmountTuoteRyhma +
    filterAmountMalli +
    filterAmountAlaRyhma +
    filterAmountMateriaali +
    filterAmountMateriaaliOminaisuus +
    filterAmountPainoLuokka +
    filterAmountAjankohtaista +
    filterAmountKayttovinkki +
    filterAmountBrandi +
    filterAmountVari +
    filterAmountKaulaAukko +
    filterAmountKoko +
    filterAmountAmmattiryhma +
    filterAmountStandardi +
    filterAmountIstutus +
    filterAmountGenre

  // Kun tuotelistaukset on valmiina, renderöidään komponentti
  if (setItemList && defaultList && defaultList.length) {
    return (
      <div className='filterBox'>
        <h6 className='desktop-only' style={{ textTransform: 'uppercase' }}>
          <FormattedMessage id='filter.title_desktop' />
        </h6>
        <p className='mobile-only' style={{ marginBottom: '8px', fontSize: '15px', textTransform: 'uppercase' }}>
          <strong>
            <FormattedMessage id='filter.title_mobile' />
          </strong>
        </p>
        <p className='mobile-only' style={{ marginBottom: '16px', fontSize: '15px' }}>
          {title}
        </p>

        {tuoteRyhmaOn && jsxTuoteryhma ? (
          <>
            <div className='filterTitle' onClick={() => toggleHideTuoteRyhma(!hideTuoteRyhma)}>
              <Form.Label>
                <FormattedMessage id='filter.product_group' />{' '}
                <span className='filterAmount' hidden={filterAmountTuoteRyhma === 0}>
                  {filterAmountTuoteRyhma}
                </span>
              </Form.Label>
              <img
                src={chevron}
                alt=''
                width='10'
                className={hideTuoteRyhma ? null : 'rotate180'}
              />
            </div>

            <Form.Group className='filterCheckBoxes' hidden={hideTuoteRyhma}>
              {jsxTuoteryhma}
            </Form.Group>
          </>
        ) : null}

        {alaRyhmaOn && jsxAlaryhma ? (
          <>
            <div
              className={lockAlaRyhma ? 'filterTitle lockedFilter' : 'filterTitle'}
              onClick={() => toggleHideAlaRyhma(!hideAlaRyhma)}>
              <Form.Label>
                <FormattedMessage id='filter.sub_group' />{' '}
                <span className='filterAmount' hidden={filterAmountAlaRyhma === 0}>
                  {filterAmountAlaRyhma}
                </span>
              </Form.Label>
              <img src={chevron} alt='' width='10' className={hideAlaRyhma ? null : 'rotate180'} />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideAlaRyhma}>
              {jsxAlaryhma}
            </Form.Group>
          </>
        ) : null}

        {istutusOn && jsxIstutus ? (
          <>
            <div
              className={lockIstutus ? 'filterTitle lockedFilter' : 'filterTitle'}
              onClick={() => toggleHideIstutus(!hideIstutus)}>
              <Form.Label>
                <FormattedMessage id='filter.fit' />{' '}
                <span className='filterAmount' hidden={filterAmountIstutus === 0}>
                  {filterAmountIstutus}
                </span>
              </Form.Label>
              <img src={chevron} alt='' width='10' className={hideIstutus ? null : 'rotate180'} />
            </div>

            <Form.Group className='filterCheckBoxes' hidden={hideIstutus}>
              {jsxIstutus}
            </Form.Group>
          </>
        ) : null}

        {kaulaAukkoOn && jsxKaulaAukko ? (
          <>
            {' '}
            <div
              className={lockKaulaAukko ? 'filterTitle lockedFilter' : 'filterTitle'}
              onClick={() => toggleHideKaulaAukko(!hideKaulaAukko)}>
              <Form.Label>
                <FormattedMessage id='filter.neckline' />{' '}
                <span className='filterAmount' hidden={filterAmountKaulaAukko === 0}>
                  {filterAmountKaulaAukko}
                </span>
              </Form.Label>
              <img
                src={chevron}
                alt=''
                width='10'
                className={hideKaulaAukko ? null : 'rotate180'}
              />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideKaulaAukko}>
              {jsxKaulaAukko}
            </Form.Group>
          </>
        ) : null}

        {kokoOn && jsxKoko ? (
          <>
            {' '}
            <div
              className={lockKoko ? 'filterTitle lockedFilter' : 'filterTitle'}
              onClick={() => toggleHideKoko(!hideKoko)}>
              <Form.Label>
                <FormattedMessage id='filter.size' />{' '}
                <span className='filterAmount' hidden={filterAmountKoko === 0}>
                  {filterAmountKoko}
                </span>
              </Form.Label>
              <img
                src={chevron}
                alt=''
                width='10'
                className={hideKoko ? null : 'rotate180'}
              />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideKoko}>
              {jsxKoko}
            </Form.Group>
          </>
        ) : null}

        {malliOn && jsxMalli ? (
          <>
            <div
              className={lockMalli ? 'filterTitle lockedFilter' : 'filterTitle'}
              onClick={() => toggleHideMalli(!hideMalli)}>
              <Form.Label>
                <FormattedMessage id='filter.model' />{' '}
                <span className='filterAmount' hidden={filterAmountMalli === 0}>
                  {filterAmountMalli}
                </span>
              </Form.Label>
              <img src={chevron} alt='' width='10' className={hideMalli ? null : 'rotate180'} />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideMalli}>
              {jsxMalli}
            </Form.Group>
          </>
        ) : null}

        {materiaaliOn && jsxMateriaali ? (
          <>
            <div
              className={lockMateriaali ? 'filterTitle lockedFilter' : 'filterTitle'}
              onClick={() => toggleHideMateriaali(!hideMateriaali)}>
              <Form.Label>
                <FormattedMessage id='filter.material' />{' '}
                <span className='filterAmount' hidden={filterAmountMateriaali === 0}>
                  {filterAmountMateriaali}
                </span>
              </Form.Label>
              <img
                src={chevron}
                alt=''
                width='10'
                className={hideMateriaali ? null : 'rotate180'}
              />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideMateriaali}>
              {jsxMateriaali}
            </Form.Group>
          </>
        ) : null}

        {materiaaliOminaisuusOn && jsxMateriaaliOminaisuus ? (
          <>
            <div
              className={lockMateriaaliOminaisuus ? 'filterTitle lockedFilter' : 'filterTitle'}
              onClick={() => toggleHideMateriaaliOminaisuus(!hideMateriaaliOminaisuus)}>
              <Form.Label>
                <FormattedMessage id='filter.material_property' />{' '}
                <span className='filterAmount' hidden={filterAmountMateriaaliOminaisuus === 0}>
                  {filterAmountMateriaaliOminaisuus}
                </span>
              </Form.Label>
              <img
                src={chevron}
                alt=''
                width='10'
                className={hideMateriaaliOminaisuus ? null : 'rotate180'}
              />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideMateriaaliOminaisuus}>
              {jsxMateriaaliOminaisuus}
            </Form.Group>
          </>
        ) : null}

        {painoLuokkaOn && jsxPainoluokka ? (
          <>
            {' '}
            <div
              className={lockPainoLuokka ? 'filterTitle lockedFilter' : 'filterTitle'}
              onClick={() => toggleHidePainoLuokka(!hidePainoLuokka)}>
              <Form.Label>
                <FormattedMessage id='filter.weight_class' />{' '}
                <span className='filterAmount' hidden={filterAmountPainoLuokka === 0}>
                  {filterAmountPainoLuokka}
                </span>
              </Form.Label>
              <img
                src={chevron}
                alt=''
                width='10'
                className={hidePainoLuokka ? null : 'rotate180'}
              />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hidePainoLuokka}>
              {jsxPainoluokka}
            </Form.Group>
          </>
        ) : null}

        {ajankohtaistaOn && jsxAjankohtaista ? (
          <>
            <div className='filterTitle' onClick={() => toggleHideAjankohtaista(!hideAjankohtaista)}>
              <Form.Label>
                <FormattedMessage id='filter.relevant' />{' '}
                <span className='filterAmount' hidden={filterAmountAjankohtaista === 0}>
                  {filterAmountAjankohtaista}
                </span>
              </Form.Label>
              <img src={chevron} alt='' width='10' className={hideAjankohtaista ? null : 'rotate180'} />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideAjankohtaista}>
              {jsxAjankohtaista}
            </Form.Group>
          </>
        ) : null}

        {ammattiryhmaOn && jsxAmmattiryhma ? (
          <>
            {' '}
            <div
              className='filterTitle'
              onClick={() => toggleHideAmmattiryhma(!hideAmmattiryhma)}>
              <Form.Label>
                <FormattedMessage id='filter.profession' />{' '}
                <span className='filterAmount' hidden={filterAmountAmmattiryhma === 0}>
                  {filterAmountAmmattiryhma}
                </span>
              </Form.Label>
              <img
                src={chevron}
                alt=''
                width='10'
                className={hideAmmattiryhma ? null : 'rotate180'}
              />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideAmmattiryhma}>
              {jsxAmmattiryhma}
            </Form.Group>
          </>
        ) : null}

        {genreOn && jsxGenre ? (
          <>
            <div className='filterTitle' onClick={() => toggleHideGenre(!hideGenre)}>
              <Form.Label>
                <FormattedMessage id='filter.genre' />{' '}
                <span className='filterAmount' hidden={filterAmountGenre === 0}>
                  {filterAmountGenre}
                </span>
              </Form.Label>
              <img src={chevron} alt='' width='10' className={hideGenre ? null : 'rotate180'} />
            </div>

            <Form.Group className='filterCheckBoxes' hidden={hideGenre}>
              {jsxGenre}
            </Form.Group>
          </>
        ) : null}

        {kayttovinkkiOn && jsxKayttovinkki ? (
          <>
            {' '}
            <div className='filterTitle' onClick={() => toggleHideKayttovinkki(!hideKayttovinkki)}>
              <Form.Label>
                <FormattedMessage id='filter.instructions' />{' '}
                <span className='filterAmount' hidden={filterAmountKayttovinkki === 0}>
                  {filterAmountKayttovinkki}
                </span>
              </Form.Label>
              <img
                src={chevron}
                alt=''
                width='10'
                className={hideKayttovinkki ? null : 'rotate180'}
              />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideKayttovinkki}>
              {jsxKayttovinkki}
            </Form.Group>
          </>
        ) : null}

        {standardiOn && jsxStandardi ? (
          <>
            {' '}
            <div
              className='filterTitle'
              onClick={() => toggleHideStandardi(!hideStandardi)}>
              <Form.Label>
                <FormattedMessage id='filter.standard' />{' '}
                <span className='filterAmount' hidden={filterAmountStandardi === 0}>
                  {filterAmountStandardi}
                </span>
              </Form.Label>
              <img
                src={chevron}
                alt=''
                width='10'
                className={hideStandardi ? null : 'rotate180'}
              />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideStandardi}>
              {jsxStandardi}
            </Form.Group>
          </>
        ) : null}

        {brandiOn && jsxBrandi ? (
          <>
            {' '}
            <div className='filterTitle' onClick={() => toggleHideBrandi(!hideBrandi)}>
              <Form.Label>
                <FormattedMessage id='filter.brand' />{' '}
                <span className='filterAmount' hidden={filterAmountBrandi === 0}>
                  {filterAmountBrandi}
                </span>
              </Form.Label>
              <img src={chevron} alt='' width='10' className={hideBrandi ? null : 'rotate180'} />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideBrandi}>
              {jsxBrandi}
            </Form.Group>
          </>
        ) : null}

        {variOn && jsxVari ? (
          <>
            {' '}
            <div className='filterTitle' onClick={() => toggleHideVari(!hideVari)}>
              <Form.Label>
                <FormattedMessage id='filter.color' />{' '}
                <span className='filterAmount' hidden={filterAmountVari === 0}>
                  {filterAmountVari}
                </span>
              </Form.Label>
              <img src={chevron} alt='' width='10' className={hideVari ? null : 'rotate180'} />
            </div>
            <Form.Group className='filterCheckBoxes' hidden={hideVari}>
              {jsxVari}
            </Form.Group>
          </>
        ) : null}

        <Form.Label style={{ marginTop: '8px' }}>
          <FormattedMessage id='filter.starting_price' /> (€)
        </Form.Label>
        <Form.Group id='priceRange'>
          <div className='priceRangeInput'>
            <input
              type='number'
              min={0}
              max={999}
              maxLength={3}
              value={hintaValue[0] || ''}
              step={5}
              onChange={(e) => handlePriceChange(e.target.value, 0)}
            />
            <Form.Text>Min.</Form.Text>
          </div>
          <div className='priceRangeInput'>
            <input
              type='number'
              min={0}
              max={999}
              maxLength={3}
              value={hintaValue[1] || ''}
              step={5}
              onChange={(e) => handlePriceChange(e.target.value, 1)}
            />
            <Form.Text>Max.</Form.Text>
          </div>
        </Form.Group>

        <Button variant='outline-secondary' className='desktop-only' onClick={() => resetFilter()}>
          <FormattedMessage id='filter.clear_filters' />
        </Button>

        {tagTuoteRyhma ||
        tagAlaryhma ||
        tagIstutus ||
        tagKaulaAukko ||
        tagKoko ||
        tagMalli ||
        tagMateriaali ||
        tagMateriaaliOminaisuus ||
        tagPainoLuokka ||
        tagAjankohtaista ||
        tagAmmattiryhma ||
        tagGenre ||
        tagKayttovinkki ||
        tagStandardi ||
        tagBrandi ||
        tagVari ? (
          <div className='tagsContainer'>
            <div className='tagTitle'>
              <p style={{ textTransform: 'uppercase' }}>
                <strong>
                  <FormattedMessage id='filter.selected_filters' />
                </strong>
              </p>
              {sumOfFilter > 2 ? (
                <Badge pill variant='secondary'>
                  <button onClick={() => resetFilter()}>
                    <FormattedMessage id='filter.clear_filters' />
                  </button>
                </Badge>
              ) : null}
            </div>
            <div className='tags'>
              {tagTuoteRyhma && tagTuoteRyhma.length ? tagTuoteRyhma : null}
              {tagAlaryhma && tagAlaryhma.length ? tagAlaryhma : null}
              {tagIstutus && tagIstutus.length ? tagIstutus : null}
              {tagKaulaAukko && tagKaulaAukko.length ? tagKaulaAukko : null}
              {tagKoko && tagKoko.length ? tagKoko : null}
              {tagMalli && tagMalli.length ? tagMalli : null}
              {tagMateriaali && tagMateriaali.length ? tagMateriaali : null}
              {tagMateriaaliOminaisuus && tagMateriaaliOminaisuus.length ? tagMateriaaliOminaisuus : null}
              {tagPainoLuokka && tagPainoLuokka.length ? tagPainoLuokka : null}
              {tagAjankohtaista && tagAjankohtaista.length ? tagAjankohtaista : null}
              {tagAmmattiryhma && tagAmmattiryhma.length ? tagAmmattiryhma : null}
              {tagGenre && tagGenre.length ? tagGenre : null}
              {tagKayttovinkki && tagKayttovinkki.length ? tagKayttovinkki : null}
              {tagStandardi && tagStandardi.length ? tagStandardi : null}
              {tagBrandi && tagBrandi.length ? tagBrandi : null}
              {tagVari && tagVari.length ? tagVari : null}
            </div>
          </div>
        ) : null}

        <div className='mobile-only mobile-filter-buttons'>
          <Button variant='light' onClick={() => resetFilter()}>
            <FormattedMessage id='filter.clear_filters' />
          </Button>
          <Button
            variant='black'
            onClick={() => {
              setShowFilter(false)
              window.scrollTo(0, 0)
            }}>
            <FormattedMessage id='filter.show_products' /> ({itemList.length})
          </Button>
        </div>

        <div style={{ width: '100%', marginBottom: '80px' }} className='mobile-only' />
      </div>
    )
  } else {
    return null
  }
}

export default Filter
